import dark from "./dark"

export default {
    ...dark,

    "--background-color": "#f3f3f3",
    "--page-background-color": "#ffffff",
    "--obj-background-color": "#e9e9e9",
    "--obj-background-color-hover": "#d1d1d1",
    "--text-color": "#242424",
    "--text-color-alpha": "#07070788",
    "--tab-color": "#DDDDDD",
    "--tab-color-selected": "#C9C9C9",


    "--highlight-tab": "#878787",

    "--highlight-emphasis": "#a8a8a2",
    "--highlight-primitive": "#a9c43a",
    "--highlight-class": "#26eb37",
    "--highlight-attr": "#287d8d",
    "--highlight-params": "#242424",
    "--highlight-type": "#eb2668",
    "--highlight-meta": "#eb2668EE",
    "--highlight-comment": "#0000006e",
    "--highlight-string": "#2c9160",
    "--highlight-keyword": "#3067ff",
    "--highlight-tag": "#3067ff",
}